/**
 * @file Utilities for improved typing of query objects for API requests.
 */

export enum Filter {
  Equals = 'equals',
  In = 'in',
  NotIn = 'not_in',
}

export enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * Generates a record type where the keys are the combined name and filter with the given type.
 *
 * Ex: FilterKeys<'status', Filter.In | Filter.NotIn, string[]> generates
 * {
 *   'status:in': string[];
 *   'status:not_in': string[];
 * }
 *
 * Multiple filter records can be combined with '&':
 *  type QueryFilters = FilterKeys<'id', Filter.Equals, string> &
 *    FilterKeys<'status', Filter.In | Filter.NotIn, string[]>;
 */
export type FilterKeys<N extends string, F extends Filter, T> = {
  [K in `${N}:${F}`]?: T;
};

/**
 * Generates the values for a given sort key.
 *
 * Ex: SortValues<'id', Sort.Asc | Sort.Desc> generates 'id:asc' | 'id:desc'
 *
 * Multiple sort values can be combined with '|':
 *  type AllowableSortValues = SortValues<'id', Sort.asc | Sort.Desc> |
 *    SortValues<'status', Sort.asc | Sort.Desc>;
 */
export type SortValues<N extends string, S extends Sort> = `${N}:${S}`;

/**
 * Generates the standard sort record entry with the given sortable keys, with
 * ascending and descending for each.
 *
 * Ex: StandardSort<'id' | 'status'> generates
 * {
 *   sort: 'id:asc' | 'id:desc' | 'status:asc' | 'status:desc';
 * }
 */
export type StandardSort<E extends string> = {
  sort?: SortValues<E, Sort.Asc | Sort.Desc>;
};
