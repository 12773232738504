import { DataOptions } from 'vuetify';

/**
 * WARNING: the code exported here is a duplicated module.
 * Consider importing from here: packages/cosmos/src/models/table.models.ts
 */
export const DEFAULT_TABLE_PAGINATION = 25;

/**
 * WARNING: the code exported here is a duplicated module.
 * Consider importing from here: packages/cosmos/src/models/table.models.ts
 */
export class VuetifyDataTableOptions<Row> implements DataOptions {
  page = 1;

  itemsPerPage: number = DEFAULT_TABLE_PAGINATION;

  sortBy: Array<string & keyof Row> = [];

  sortDesc: boolean[] = [];

  groupBy: string[] = [];

  groupDesc: boolean[] = [];

  multiSort = false;

  mustSort = false;

  constructor(init: Partial<VuetifyDataTableOptions<Row>>) {
    Object.assign(this, init);
  }
}
