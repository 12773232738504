// TODO: Delete this once all column selectors have been replaced
import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

/**
 * @param defaultHeaders The set of columns to show by default
 * @param queryColumns The columns found after parsing the browsers query state (usually from the URL and local storage)
 * @param allPossibleHeaders The full set of columns to match queryColumns against
 * @returns {TableHeader[]} The set of headers to ultimately be displayed
 */
export function getColumnsFromQueryState(
  defaultHeaders: DataTableHeader[],
  queryColumns: string[],
  allPossibleHeaders = defaultHeaders,
): DataTableHeader[] {
  return queryColumns.length > 0
    ? (queryColumns as string[]).reduce((acc, columnName) => {
        const found = allPossibleHeaders.find(
          ({ value }) => value === columnName,
        );
        return found ? [...acc, found] : acc;
      }, [] as DataTableHeader[])
    : defaultHeaders;
}

export function useColumnSelector(
  defaultHeaders: DataTableHeader[],
  exceptionColumns = ['display_name'],
) {
  const visibleColumns = ref<string[]>(
    defaultHeaders.map((header) => header.value),
  );
  const columnOrder = ref<string[]>(
    defaultHeaders.map((header) => header.value),
  );
  const selectableColumns = computed(() => {
    return defaultHeaders
      .reduce((columns: DataTableHeader[], { text, value }) => {
        if (exceptionColumns.includes(value)) {
          return columns;
        }

        return [...columns, { text, value }];
      }, [])
      .sort(orderColumnSort);
  });

  const headers = computed(() => {
    return defaultHeaders
      .filter((header) => {
        return (
          exceptionColumns.includes(header.value) ||
          visibleColumns.value.indexOf(header.value) > -1
        );
      })
      .sort(orderColumnSort);
  });

  function onOrderChange({ moved }) {
    const columns = [...columnOrder.value];
    const columnToMove = columns.splice(moved.oldIndex + 1, 1)[0];
    columns.splice(moved.newIndex + 1, 0, columnToMove);
    columnOrder.value = columns;
  }

  function orderColumnSort(a, b) {
    const aColumn = columnOrder.value.indexOf(a.value);
    const bColumn = columnOrder.value.indexOf(b.value);

    if (aColumn > bColumn) {
      return 1;
    }
    if (aColumn < bColumn) {
      return -1;
    }

    return 0;
  }

  return {
    headers,
    visibleColumns,
    columnOrder,
    selectableColumns,
    onOrderChange,
  };
}
