import { removeEmptyEntriesFromShallowObject } from '@ax/object-utils';
import { getItemInWebStorage, StorageConfig } from '@ax/web-storage-wrapper';

type AnyPrimitive = string | number | boolean | symbol | null | undefined;

export interface QueryStateParameterConfiguration<
  State,
  Key extends keyof State = keyof State,
> {
  /**
   * Required.  Set a reasonable default with the correct data type.  If the possible value is an array,
   * the default value should probably be an empty array
   */
  defaultValue: State[Key];

  /**
   * When state changes, should this property be persisted in browser storage?
   */
  storeInBrowser?: boolean;

  /**
   * Should the property be omitted from the search string in the browser's URL?
   */
  excludeFromBrowserUrl?: boolean;

  /**
   * Should the property be omitted as a query parameter in API URLs?
   */
  excludeFromApiUrl?: boolean;

  /**
   * If a parameter is an array containing a set number of possible filters, set the length of
   * possible filter values here.  If all possible filters are selected, the parameter will be
   * removed from queries because filtering on all possible values is a no-op.
   */
  dataFilterMaxLength?: () => number | undefined;

  /**
   * Should this property be sent to the API as `"&filters[key]=value"`
   */
  isApiFilter?: boolean;

  /**
   * Transform UI keys to keys the API expects
   */
  apiKeyTranslation?: string;

  /**
   * Transform UI values to values the API expects `new Map().set(fromUiValue, toApiValue)`
   */
  apiValueTranslations?: Map<State[Key], AnyPrimitive>;

  /**
   * Used for supporting old bookmarks users may have. For example:
   * `sort_by: { legacyUiKeyTranslation: 'orderby' }`
   */
  legacyUiKeyTranslation?: string;

  /**
   * Used for supporting old bookmarks users may have.
   * `new Map().set(standardized_new_value, old-Value-To-Treat-As-Equivalent-To-New-Value)`
   */
  legacyUiValueTranslations?: Map<State[Key], AnyPrimitive>;
}

export interface QueryStateManagementOptions<State> {
  onBeforeUiUrlGeneration?: (State) => State;
  storageConfig?: StorageConfig;
  mergeStore?: boolean;
  onBeforeApiUrlGeneration?: (State) => State;
}

export type QueryStateParametersConfiguration<State> = {
  [key in keyof State]: QueryStateParameterConfiguration<State, key>;
};

export type ResolvedStateObject<State> = {
  [key in keyof State]: QueryStateParameterConfiguration<
    State,
    key
  >['defaultValue'];
};

/**
 * Generate a state object from configured default values.
 * Use this when coordination with the browser URL and broser storage is not desired.
 * @param configuration an object describing properties expected in a URL query and default values for each
 * property in case they are not present in the URL or browser storage
 */
export class QueryState {
  public static fromConfigurationDefaults<State>(
    configuration: QueryStateParametersConfiguration<State>,
  ): ResolvedStateObject<State> {
    return Object.entries<QueryStateParameterConfiguration<State>>(
      configuration,
    ).reduce(
      (acc, [key, config]) => ({
        ...acc,
        [key]: config.defaultValue,
      }),
      {} as ResolvedStateObject<State>,
    );
  }

  /**
   * A consistent way to build a UI state object from various state sources. `configuration.defaults` is
   * overridden by any values found in `options.storageConfig`, and `urlQuery` overrides all.
   * @param configuration an object describing properties expected in a URL query and default values for each
   * property in case they are not present in the URL or browser storage
   * @param urlQuery the browser's URL query string
   * @param options
   *   - `storageConfig`: which store to use (likely localStorage or sessionStorage) and key to use
   */
  public static fromUrlAndStorage<State>(
    configuration: QueryStateParametersConfiguration<State>,
    urlQuery: string,
    options: QueryStateManagementOptions<State> = {},
  ): ResolvedStateObject<State> {
    const { storageConfig } = options;

    function parseNestedValues(paramValue: string): string | boolean | number {
      if (/^\d+$/.test(paramValue)) {
        return parseFloat(paramValue);
      }
      if (paramValue === 'true' || paramValue === 'false') {
        return paramValue === 'true';
      }
      return paramValue;
    }

    const params = new URLSearchParams(urlQuery.replace(/.*\?/, ''));
    const parsedUrl = Object.entries(
      Object.fromEntries(params.entries()),
    ).reduce((acc, [k, v]) => {
      const param = v.includes(',')
        ? v.split(',').map(parseNestedValues)
        : parseNestedValues(v);

      return { ...acc, [k]: param };
    }, {});

    const { defaults, fromUrl } = Object.entries<
      QueryStateParameterConfiguration<State>
    >(
      configuration as { [s: string]: QueryStateParameterConfiguration<State> },
    ).reduce(
      (acc, [key, config]) => {
        acc.defaults[key] = config.defaultValue;

        const urlHasCurrentProperty =
          Reflect.has(parsedUrl, key) ||
          Reflect.has(parsedUrl, config.legacyUiKeyTranslation!);
        if (urlHasCurrentProperty) {
          acc.fromUrl[key] = parsedUrl[key];
        } else {
          return acc;
        }

        if (
          config.legacyUiKeyTranslation &&
          Reflect.has(parsedUrl, config.legacyUiKeyTranslation)
        ) {
          acc.fromUrl[key] = parsedUrl[config.legacyUiKeyTranslation];
        }
        if (
          Array.isArray(config.defaultValue) &&
          !Array.isArray(acc.fromUrl[key])
        ) {
          acc.fromUrl[key] = urlHasCurrentProperty ? [acc.fromUrl[key]] : [];
        }
        if (
          config.legacyUiValueTranslations &&
          config.legacyUiValueTranslations.has(acc.fromUrl[key])
        ) {
          acc.fromUrl[key] = config.legacyUiValueTranslations.get(
            acc.fromUrl[key],
          );
        }
        return acc;
      },
      { defaults: {}, fromUrl: {} },
    );

    const fromStorage = storageConfig ? getItemInWebStorage(storageConfig) : {};

    return {
      ...defaults,
      ...fromStorage,
      ...fromUrl,
    };
  }

  /**
   * Apply configuration to state andnerate an object representing a query that can be sent to the API
   * @param hooked UI-formatted state object that needs to be translated
   * @param configuration {QueryStateParametersConfiguration}
   */
  public static toApiUrlQuery<State>(
    state: State,
    configuration: QueryStateParametersConfiguration<State>,
    options: QueryStateManagementOptions<State> = {},
  ): Partial<State> & { filters?: Partial<State> } {
    const { onBeforeApiUrlGeneration } = options;
    const deleteKeys: string[] = [];

    const hooked = onBeforeApiUrlGeneration
      ? onBeforeApiUrlGeneration(state)
      : state;

    const translated = Object.entries(hooked).reduce((acc, [key, value]) => {
      let resolvedKey = key;
      if (configuration[key].apiKeyTranslation) {
        resolvedKey = configuration[key].apiKeyTranslation;
        deleteKeys.push(key);
      }

      if (!configuration[key].excludeFromApiUrl) {
        acc[resolvedKey] = hooked[key];
      } else {
        return acc;
      }

      if (
        configuration[key].apiValueTranslations &&
        configuration[key].apiValueTranslations.has(value)
      ) {
        acc[resolvedKey] = configuration[key].apiValueTranslations.get(value);
      }

      const maxLength =
        configuration[key].dataFilterMaxLength &&
        configuration[key].dataFilterMaxLength();

      if (
        maxLength &&
        acc[resolvedKey].length &&
        acc[resolvedKey].length >= maxLength
      ) {
        deleteKeys.push(key);
      }
      if (configuration[key].isApiFilter) {
        if (
          !Array.isArray(acc[resolvedKey]) ||
          !maxLength ||
          (Array.isArray(acc[resolvedKey]) &&
            maxLength &&
            (!maxLength || maxLength > acc[resolvedKey].length))
        ) {
          acc.filters = acc.filters
            ? { ...acc.filters, [resolvedKey]: acc[resolvedKey] }
            : { [resolvedKey]: acc[resolvedKey] };
        }
        deleteKeys.push(resolvedKey);
      }
      return acc;
    }, {} as State & { filters?: {} });

    const cleaned = translated.filters
      ? {
          ...translated,
          filters: removeEmptyEntriesFromShallowObject(translated.filters),
        }
      : translated;

    return removeEmptyEntriesFromShallowObject(
      cleaned,
      deleteKeys as Array<keyof State>,
    );
  }

  /**
   * Apply configuration to state to generate a URL query string that can be sent to the API
   * It is encoded with brackets in the jQuery.param style.
   * @example ```ts
   * const apiQuery = QueryState.toApiQuery(state, configuration, options);
   * axios.get(SOME_ENDPOINT, {
   *    params: apiQuery,
   *    paramsSerializer: QueryState.toApiUrlQueryStringWithBrackets,
   * });
   * ```
   */
  public static toApiUrlQueryStringWithBrackets<State>(
    state: Partial<State> & object,
  ): string {
    const sortedParamsObject = sortObjectByKeys(state);

    const params = new URLSearchParams();

    function appendBracketedArrayParams(
      key: string,
      array: Array<string>,
    ): void {
      array.forEach((v) => params.append(`${key}[]`, v));
    }

    Object.entries(sortedParamsObject).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        appendBracketedArrayParams(key, value as string[]);
      } else if (typeof value === 'object') {
        Object.entries(sortObjectByKeys(value as object)).forEach(([k, v]) => {
          const bracketKey = `${key}[${k}]`;
          if (Array.isArray(v)) {
            appendBracketedArrayParams(bracketKey, v);
          } else {
            params.append(bracketKey, v as string);
          }
        });
      } else {
        params.append(key, String(value));
      }
    });

    return params.toString().replace(/%5B/g, '[').replace(/%5D/g, ']');
  }

  /**
   * Apply configuration to state and generate an object representing what the UI URL query should be
   * @param state
   * @param configuration
   */
  public static toUiUrlQuery<State>(
    state: State & object,
    configuration: QueryStateParametersConfiguration<State>,
    options: QueryStateManagementOptions<State> = {},
  ): Partial<State> {
    const { onBeforeUiUrlGeneration } = options;

    const hooked = (
      onBeforeUiUrlGeneration ? onBeforeUiUrlGeneration(state) : state
    ) as State & object;

    const removeKeys = Object.entries<QueryStateParameterConfiguration<State>>(
      configuration,
    ).reduce((acc, [key, config]) => {
      const maxLength =
        config.dataFilterMaxLength && config.dataFilterMaxLength();
      const isOverMaxLength =
        maxLength &&
        Array.isArray(hooked[key]) &&
        hooked[key].length >= maxLength;
      if (config.excludeFromBrowserUrl || isOverMaxLength) {
        acc.push(key as keyof State);
      }
      return acc;
    }, [] as Array<keyof State>);

    return removeEmptyEntriesFromShallowObject(hooked, removeKeys);
  }

  /**
   * Apply configuration to state to generate a URLSearchParams
   * with standard object and array serialization
   * @param state
   * @param configuration
   */
  public static toUiUrlSearchParams<State>(
    state: State & object,
    configuration: QueryStateParametersConfiguration<State>,
    options: QueryStateManagementOptions<State> = {},
  ): URLSearchParams {
    const uiParams = QueryState.toUiUrlQuery(state, configuration, options);

    const encoded = Object.entries(uiParams).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.join(',');
      } else {
        acc[key] = String(value);
      }
      return acc;
    }, {} as Record<string, string>);

    return new URLSearchParams(encoded);
  }
}

function sortObjectByKeys<T extends object>(object: T): T {
  return Object.keys(object)
    .sort()
    .reduce((acc, next) => ({ ...acc, [next]: object[next] }), {} as T);
}
