import VueRouter, { Route } from 'vue-router';
import { Ref, shallowRef, watch } from '@vue/composition-api';

import { DataTableHeader } from 'vuetify';

import {
  QueryState,
  QueryStateParametersConfiguration,
  QueryStateParameterConfiguration,
  QueryStateManagementOptions,
} from '@ax/url-query-state';
import { setItemInWebStorage, Storable } from '@ax/web-storage-wrapper';
import { prop } from '@ax/object-utils';

import {
  DEFAULT_TABLE_PAGINATION,
  VuetifyDataTableOptions,
} from './table.models';

export type SortDirection = 'asc' | 'desc';

export interface PaginatedAPIQuery<Row> {
  sort: keyof Row | Array<keyof Row>;
  dir: string;
  p: number;
  l: number;
}

export interface DataTableSynchronizationOptions<State>
  extends QueryStateManagementOptions<State> {
  synchronizeApiOnly?: boolean;
  callback?: ({ apiQuery: State }) => void;
  // Some API endpoints (software) pagination starts at 0, while others (history) start at 1.
  // This allows us to set the starting index to 1 instead of the default of 0.
  pageIndexStartingValue?: 0 | 1;
  ignoreFirstTableUpdate?: boolean;
  skipPageResets?: boolean;
}

/*
 * Contains all standard data table state variables (AKA DataOptions from Vuetify).
 * Used in data sync setup to compile the initial raw table state object.
 */
export class VuetifyDataTableState<Row> extends VuetifyDataTableOptions<Row> {
  constructor(init: Partial<VuetifyDataTableState<Row>>) {
    super(init);
  }

  /**
   * Given a standard query state config, return a complete query + table state (including sortBy
   * and sortDesc fields). Reminder: queryState encompasses tableState.
   * Used in data sync setup.
   * @param state - the given query configuration
   * @returns the new table state configuration (mirrors DataOptions type in Vuetify)
   */
  public static fromQueryState<Row>(
    state: DataTableUiUrlParams<Row>,
  ): VuetifyDataTableState<Row> {
    const base = { page: state.page, itemsPerPage: state.limit };

    if (state.sort) {
      const [field, direction] = state.sort.split(':') as [
        string & keyof Row,
        SortDirection,
      ];
      return new VuetifyDataTableState<Row>({
        ...base,
        sortBy: [field],
        sortDesc: [direction === 'desc'],
      });
    }

    return new VuetifyDataTableState<Row>({
      ...base,
      sortBy: [state.sort_by!],
      sortDesc: [state.sort_dir === 'desc'],
    });
  }
}

/*
 * Class pertaining to standard query state settings. Used in data sync setup.
 */
export class DataTableUiUrlParams<Row> {
  page = 1;

  limit: number = DEFAULT_TABLE_PAGINATION;

  sort_by?: string & keyof Row;

  sort_dir?: SortDirection;

  sort?: string;

  /**
   * Generate DataTableUiUrlParams (query state) from Vuetify data table state variables.
   * e.g. format ascending/descending params using colon notation: "name:asc";
   * Used in data state update.
   * @param options - the recently-updated param configuration
   * @param configuration - the original param configuration object (3rd param passed to DataTableState.synchronize())
   * @returns a new param configuration
   */
  public static fromTableState<Row, State extends DataTableUiUrlParams<Row>>(
    options: VuetifyDataTableState<Row>,
    configuration: QueryStateParametersConfiguration<State>,
  ): DataTableUiUrlParams<Row> {
    const base = { page: options.page, limit: options.itemsPerPage };

    if (configuration.sort && options.sortBy[0]) {
      return {
        ...base,
        sort: `${options.sortBy[0] as string}:${
          options.sortDesc[0] ? 'desc' : 'asc'
        }`,
      };
    }

    if (configuration.sort_by && options.sortBy[0]) {
      return {
        ...base,
        sort_by: options.sortBy[0]!,
        sort_dir: options.sortDesc[0] ? 'desc' : 'asc',
      };
    }

    return base;
  }
}

/*
 * A class used to enforce consistency in frequently used, custom Automox query parameters.
 * Used in data sync setup.
 */
export class DataTablePageState<Row> extends DataTableUiUrlParams<Row> {
  filter_panel_open? = true;

  columns?: Array<Partial<keyof Row>> = [];

  o?: number;

  q?: string[];

  zone_id?: string;
}

export type DataTableStateMutation<State> = (
  slice: Partial<State>,
  updateOptions?: {
    debounceTime?: number;
    bypassUiUrlUpdate?: boolean;
    bypassApiUrlUpdate?: boolean;
  },
) => void;

/*
 * Class representing a DataTableState sync instance. Used in data sync setup.
 * Mostly internal; only `tableState` and `queryState` will be exposed to the end user.
 */
export class DataTableState<
  Row,
  State extends DataTablePageState<Row> = DataTablePageState<Row>,
> {
  ignoreFirstTableUpdate = false;

  ignorePaginationMutation = false;

  bypassNextApiUrlUpdate = false;

  bypassNextUiUrlUpdate = false;

  debounceTimer: number | null = null;

  ignoreForDebounce = false;

  ignoredForDebouncePrev: DataTablePageState<Row> | null = null;

  ignoredForDebounceNext: DataTablePageState<Row> | null = null;

  tableState: Ref<VuetifyDataTableState<Row>> | null = null;

  queryState: Ref<State> | null = null;

  onStateChange: ((next: State, prev: State | undefined) => void) | null = null;

  configuration: QueryStateParametersConfiguration<State>;

  /**
   * Create a DataTableState instance.
   * @param configuration - the configuration object from the component or composition
   */
  constructor(configuration: QueryStateParametersConfiguration<State>) {
    this.configuration = configuration;
  }

  /**
   * Instantiates a watch for Vuetify data table props and updates the containing query
   * state object accordingly (such as page, limit, sort...).
   * Used in data sync setup. Runs in data state update.
   */
  private watchTableState(): void {
    watch(
      this.tableState!,
      (next, prev) => {
        if (prev && this.ignoreFirstTableUpdate) {
          this.ignoreFirstTableUpdate = false;
        } else if (prev && !this.ignorePaginationMutation) {
          this.queryState!.value = {
            ...this.queryState!.value,
            ...DataTableUiUrlParams.fromTableState(
              next as VuetifyDataTableState<Row>,
              this.configuration!,
            ),
          };
        } else if (this.ignorePaginationMutation) {
          this.ignorePaginationMutation = false;
        }
      },
      {
        immediate: true,
      },
    );
  }

  /**
   * This method generates and returns the callback used by the queryState watch function.
   * It will run every time there is client-initiated change to the component instance.
   * Used in data sync setup. Runs in data state update.
   * (internal -- not intended for usage by clients of this class)
   * @param options - client-defined options. Includes the API callback function and other settings.
   * @param router - the current route from Vue's Router
   * @param route - Vue Router instance
   * @returns a function to update browser storage, the browser URL, and the API URL upon state
   * changes (scoped to this instance's management variables).
   */
  private generateOnStateChange(
    options: DataTableSynchronizationOptions<State>,
    router?: VueRouter,
    route?: Route,
  ): (next: State, prev: State | undefined) => void {
    const { storageConfig, mergeStore, callback, synchronizeApiOnly } = options;

    return (next: State, prev: State | undefined) => {
      // handle debounced changes
      if (this.ignoreForDebounce) {
        this.ignoredForDebounceNext = next;
        this.ignoredForDebouncePrev = prev as State | null;
        return;
      }
      // Vuetify data tables can emit "changed" page variables that should be ignored for the purpose
      // of avoiding duplicated API requests
      if (
        !options.skipPageResets &&
        prev &&
        prev.page === next.page &&
        next.page !== 1
      ) {
        next.page = 1;
        this.tableState!.value.page = 1;
        this.ignorePaginationMutation = true;
      } else if (next.page !== this.tableState!.value.page) {
        // Need to update the page on tableState or footer won't be updated.
        // TODO: Should we be updating the entire tableState based on updated query?
        this.tableState!.value.page = next.page;
        this.ignorePaginationMutation = true;
      }

      // save values to localStorage where applicable
      if (storageConfig) {
        const toStore = Object.entries(this.configuration).reduce(
          (acc, [key, config]) => {
            if (
              (config as QueryStateParameterConfiguration<State>).storeInBrowser
            ) {
              acc[key] = next[key];
            }
            return acc;
          },
          {} as Partial<State> & Storable,
        );

        setItemInWebStorage(toStore, storageConfig, mergeStore || false);
      }

      // check to see if the browser URL update step should be skipped
      if (
        !synchronizeApiOnly &&
        route &&
        router &&
        this.bypassNextUiUrlUpdate
      ) {
        this.bypassNextUiUrlUpdate = false;
      } else if (!synchronizeApiOnly && route && router) {
        const uiQuery = QueryState.toUiUrlSearchParams(
          next,
          this.configuration!,
          options,
        );
        router
          .replace({ query: Object.fromEntries(uiQuery.entries()) })
          .catch((error) => {
            // router.replace can be called with the same query parameters that already exist
            // ignore the navigation duplicated error.
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
          });
      }

      // check to see if the API URL update step and call to the API should be skipped
      if (this.bypassNextApiUrlUpdate) {
        this.bypassNextApiUrlUpdate = false;
      } else if (callback) {
        const apiQuery = DataTableState.toApiUrlQuery<Row, State>(
          next,
          this.configuration!,
          options,
        );
        callback({ apiQuery });
      }
    };
  }

  /**
   * Returns the updateQueryState() function so clients can trigger state changes.
   * Used in data sync setup. Runs in data state update when called by the client.
   * (scoped to this instance's management variables)
   * @returns a function for clients to call to update an instance's state
   */
  private generateUpdateQueryState() {
    return (
      slice: Partial<State>,
      updateOptions: {
        debounceTime?: number;
        bypassUiUrlUpdate?: boolean;
        bypassApiUrlUpdate?: boolean;
      } = {},
    ) => {
      const {
        debounceTime = 200,
        bypassApiUrlUpdate,
        bypassUiUrlUpdate,
      } = updateOptions;
      if (debounceTime > 0) {
        this.ignoreForDebounce = true;
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = null;
        }
        this.debounceTimer = setTimeout(() => {
          this.ignoreForDebounce = false;
          this.onStateChange!(
            this.ignoredForDebounceNext as State,
            this.ignoredForDebouncePrev as State,
          );
        }, debounceTime);
      }

      if (bypassApiUrlUpdate) {
        this.bypassNextApiUrlUpdate = true;
      }
      if (bypassUiUrlUpdate) {
        this.bypassNextUiUrlUpdate = true;
      }

      this.queryState!.value = {
        ...this.queryState!.value,
        ...slice,
      };
    };
  }

  /**
   * Returns the updateColumnsState() function so clients can trigger column state changes.
   */
  private generateUpdateColumnsState = (
    updateQueryState: DataTableStateMutation<State>,
  ) => {
    return (columns: readonly DataTableHeader[]) => {
      updateQueryState(
        {
          columns: columns.map(prop('value')) as unknown as Array<
            keyof Array<keyof Row>
          >,
        } as Partial<State>,
        { bypassApiUrlUpdate: true },
      );
    };
  };

  /**
   * Apply configuration to state to generate a URL query string that can be sent to the API.
   * Used in data state update.
   * @param state - the data state
   * @param configuration - the original client-defined configuration object
   * @param options - additional client-defined options for updating state
   * @returns an object representing the new query state
   */
  public static toApiUrlQuery<Row, State extends DataTablePageState<Row>>(
    state: State,
    configuration: QueryStateParametersConfiguration<State>,
    options: DataTableSynchronizationOptions<State> = {},
  ): Partial<State> {
    // If the pageIndexStartingValue is set to 1, do not modify the page state;
    // otherwise, set the page state to 0.
    const translated =
      options.pageIndexStartingValue === 1
        ? state
        : { ...state, page: state.page - 1 };

    return QueryState.toApiUrlQuery(translated, configuration, options);
  }

  /**
   * Synchronize (ALL) -- the UI URL, browser storage, vuetify data table state, and API URLs.
   * THIS is the most crucial function of this workspace. See ./docs/DataTablesAndUrlQueries.md for
   * more in-depth documentation.
   *
   * When the client calls synchronize(), this function:
   * - Constructs refs for tableState (to pass to vuetify's data-table component) and queryState (to hold all state)
   *   - tableState is contained by queryState
   * - Sets up watchers to know when tableState / queryState changes
   * - Updates the browser URL and browser storage when state changes
   * - Optionally generates an API query object when state changes
   * @returns { tableState: Ref<DataTableConfiguration>; queryState: Ref<State> };
   * @param route - the current route from Vue's Router
   * @param router - Vue Router instance
   * @param configuration - an object describing properties expected in a URL query and default values for each
   * property in case they are not present in the URL or browser storage
   * @param options -
   *   - `synchronizeApiOnly`: bypass all Browser URL and Browser Storage logic
   *   - `storageConfig`: which store to use (likely localStorage or sessionStorage) and key to use
   *   - `mergeStore`: if true: merge new state with existing state in storage.  if false: overwrite
   *   - `callback`: function to execute when changes are detected and after everything is synchronized
   *   - `onBeforeUiUrlGeneration`: function that can change parts of the state object
   *     (without mutating base state) before the UI URL is generated
   *   - `onBeforeApiUrlGeneration`: function that can change parts of the state object
   *     (without mutating base state) before the API URL is generated
   *  @returns { tableState, queryState, updateQueryState, updateColumnsState }
   *  - `tableState` - a watched object to feed to the Vuetify Data Table :options prop
   *  - `queryState` - an object representative of the current query state (both browser and API queries)
   *  - `updateQueryState`- a function the client can call to update the current query state
   */
  public static synchronize<Row, State extends DataTablePageState<Row>>(
    route: Route,
    router: VueRouter,
    configuration: QueryStateParametersConfiguration<State>,
    options: DataTableSynchronizationOptions<State>,
  ): {
    tableState: Ref<VuetifyDataTableState<Row>>;
    queryState: Ref<State>;
    updateQueryState: DataTableStateMutation<State>;
    updateColumnsState: (columns: readonly DataTableHeader[]) => void;
  } {
    const { synchronizeApiOnly, ignoreFirstTableUpdate = true } = options;
    const instance = new DataTableState<Row>(configuration);

    instance.ignoreFirstTableUpdate = ignoreFirstTableUpdate;

    const queryStateRaw = synchronizeApiOnly
      ? QueryState.fromConfigurationDefaults<State>(configuration)
      : QueryState.fromUrlAndStorage<State>(
          configuration,
          route.fullPath,
          options,
        );
    const tableStateRaw =
      VuetifyDataTableState.fromQueryState<Row>(queryStateRaw);
    instance.queryState = shallowRef(queryStateRaw) as Ref<State>;
    instance.tableState = shallowRef(tableStateRaw) as Ref<
      VuetifyDataTableState<Row>
    >;

    instance.onStateChange = instance.generateOnStateChange(
      options,
      router,
      route,
    );
    const updateQueryState = instance.generateUpdateQueryState();

    const updateColumnsState =
      instance.generateUpdateColumnsState(updateQueryState);

    instance.watchTableState();
    watch(instance.queryState, instance.onStateChange, { immediate: true });

    return {
      tableState: instance.tableState,
      queryState: instance.queryState as Ref<State>,
      updateQueryState,
      updateColumnsState,
    };
  }
}
