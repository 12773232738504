/**
 * Takes an array of file type and makes it readable
 */
export function getFileTypesReadable(types: string[]): string {
  return types
    .filter((type) => type.charAt(0) === '.')
    .map((type) => type.slice(1).toLocaleUpperCase())
    .join(', ');
}

/**
 * Takes a size (in MB) and converts it to readable. Supports up to GB.
 */
export function getFileSizeReadable(size: number): string {
  if (size < 1000) {
    return `${size}MB`;
  }

  return `${Math.round(size / 100) / 10}GB`;
}
